export const Units = {
    HENRYS: "H",
    WEBERS: "Wb",
    FARADS: "F",
    OHMS: "Ω",
    VOLTS: "V",
    AMPS: "A",
    WATTS: "W",
    DECIBELS: "dB",
    TESLAS: "T",
    COULOMBS: "C",
    SIEMENS: "S",
    HERTZ: "Hz",
    SECONDS: "s",
    METERS: "m",
    INCHES: "inches",
    KILOGRAMS:"kg",
    RADIAN:"rad",
    DEGREES:"°",
    PERCENTAGE:"%",
    NEWTON:"N",
    UNITLESS:" ",
    // ... Add other units as needed
};

