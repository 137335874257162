import React, {useState} from 'react';

import logo from  './../../images/fishlogo.png'
import {LogoStyled,NavBarStyled} from "./style";

import {useNavigate} from "react-router-dom";
import {Menu} from "antd";


const Nav = () => {

    const [current, setCurrent] = useState('home');
    const navigate = useNavigate();
    const onClick = (e) => {
        setCurrent(e.key);
        navigate("/"+e.key)
    };
    const items = [
        {
            label: 'Home',
            key: 'home',
        },
        {
            label: 'About',
            key: 'about',
        },
        {
            label: 'Projects',
            key: 'projects',
        },
        {
            label: 'Tools',
            key: 'tools',
            children: [
                {
                    label: 'Calculator',
                    key: 'calculator',
                },
                {
                    label: 'ROTQuiz',
                    key: 'rotquiz',
                },
            ],
        },


    ];

//style={{backgroundColor: '#004419', color:"white", margin:"10px", padding:'30px', height: '50px', width:"350px", alignItems:"center"}}
    return(
        <NavBarStyled >
            <LogoStyled src={logo} alt={'logo here'}></LogoStyled>
            <Menu style={{backgroundColor: '#004419', color:"white", margin:"10px", padding:'30px', height: '50px', width:"300px", alignItems:"center"}} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
        </NavBarStyled>
    )
}
export default Nav

// <LogoStyled src={logo} alt={'logo here'}></LogoStyled>
// <div className={'navlinks'}>
//     <Link to="/">Home</Link>
//     <Link className="App-link" to="/projects">Projects</Link>
//     <Link className="App-link" to="/calculator">Calculator</Link>
//     <Link className="App-link" to="/about">About</Link>
// </div>