import styled from 'styled-components'
export const PageHomeStyled = styled.div`


  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 75vw;
  min-width: 360px;
  
  

  h1{font-size: 75px}





`
