import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";

Amplify.configure(config);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ConfigProvider
          theme={{
              token: {
                  colorPrimary: '#f1c40f',
                  fontFamily:"Raleway, sans-serif"
              },


          }}
      >
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
