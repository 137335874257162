import React, {useState} from 'react';
import { Form, Input, Button, Select, Checkbox, Table } from 'antd';
import {
    findParallelResistorCandidates,
    findSeriesResistorCandidates,
    formatRes,
    findNearestStandardResistors
} from './Lib/resistorMath'




const ResistorDivider = () => {
    const [form] = Form.useForm();
    const [result, setResult] = useState();
    const [pref, setPref] = useState(true);

    const CandidateTable = ( targVout, vin, candidates, configType, fixedResOrientation, fixedRes, tolerance ) => {
        let dataSource = candidates.map((candidate, index) => {
            const { r1, r2 } = typeof candidate === 'object' ? candidate : { r1: candidate };
            const combinedRes = configType === 'series' ? (r1 + r2) : (r1 * r2) / (r1 + r2);
            const { min, nom, max } = calculateRange(
                configType,
                fixedResOrientation,
                fixedRes,
                r1,
                r2,
                vin,
                tolerance
            );

            const error = ((nom - targVout) / targVout) * 100;

            return {
                key: index,
                candidate: index + 1,
                r1: formatRes(r1),
                r2: r2 ? formatRes(r2) : 'N/A',
                combinedRes: formatRes(combinedRes),
                minVout: min.toFixed(3),
                nomVout: nom.toFixed(4),
                maxVout: max.toFixed(3),
                errorVolts: (nom - targVout).toFixed(4),
                error: error.toFixed(3),
                errorValue: Math.abs(error),  // We include an absolute error value for sorting
                fixedRes: formatRes(fixedRes)
            };
        });

        // Sort the dataSource by absolute error and limit entries to 10
        dataSource = dataSource
            .sort((a, b) => a.errorValue - b.errorValue)
            .slice(0, 6)
            .map((item, index) => ({
                ...item,
                candidate: index + 1  // Renumber candidates
            }));

        // Determine column titles based on fixedResOrientation and configType
        const resPrefix = fixedResOrientation === 'top' ? 'B' : 'T';
        const fixedResColumn = { title: `R${fixedResOrientation === 'top' ? 'T' : 'B'}`, dataIndex: 'fixedRes', key: 'fixedRes' };

        // Initialize basic columns
        let columns = [
            fixedResColumn,
        ];

        // Add additional columns based on configType
        if (configType === 'single') {
            columns.push({ title: `R${fixedResOrientation === 'top' ? 'B' : 'T'}`, dataIndex: 'r1', key: 'r1' });
        } else {
            columns.push(
                { title: `R1${resPrefix}`, dataIndex: 'r1', key: 'r1' },
                { title: `R2${resPrefix}`, dataIndex: 'r2', key: 'r2' },
                { title: `${configType.charAt(0).toUpperCase() + configType.slice(1)} R`, dataIndex: 'combinedRes', key: 'combinedRes' }
            );
        }

        // Add remaining columns
        columns = [
            { title: 'Rank', dataIndex: 'candidate', key: 'candidate' },
            { title: 'Nominal Vout (V)', dataIndex: 'nomVout', key: 'nomVout' },
            { title: 'Error (V)', dataIndex: 'errorVolts', key: 'errorVolts' },
            { title: 'Error (%)', dataIndex: 'error', key: 'error' },
            ...columns,
            { title: 'Min Vout (V)', dataIndex: 'minVout', key: 'minVout' },
            { title: 'Max Vout (V)', dataIndex: 'maxVout', key: 'maxVout' },
        ];

        return (
            <Table dataSource={dataSource} columns={columns} pagination={false} />
        );
    };

    const computeSolution = (values) => {
        const vin = parseFloat(values.inputvoltage);
        const vout = parseFloat(values.outputvoltage);
        const fixedResistor = extractFloatWithPrefix(values.fixedresistance);
        let rtarg = 100e9;
        const fixedResOrientation = values.fixedresistororientation
        if (fixedResOrientation === "top"){
            rtarg = fixedResistor/((vin/vout) -1.0)
        } else {
            rtarg = fixedResistor*((vin/vout) -1.0)
        }

        console.log(rtarg)
        let resistorCandidates;
        let jsxElements;

        switch(values.orientation) {
            case 'single':
                resistorCandidates = findNearestStandardResistors(rtarg,values.tolerance,values.pref);
                break;
            case 'parallel':
                resistorCandidates = findParallelResistorCandidates(values.tolerance, rtarg,values.pref);
                break;
            case 'series':
                resistorCandidates = findSeriesResistorCandidates(values.tolerance, rtarg,values.pref);
                break;
            default:
                resistorCandidates = null;
                break;
        }

        jsxElements = CandidateTable(vout, vin, resistorCandidates, values.orientation, fixedResOrientation, fixedResistor, values.tolerance)
        setResult(jsxElements)
        return (
            <div>
                {jsxElements}
            </div>
        );




    };

    const calculateRange = (configType, fixedResOrientation, fixedRes, r1, r2, vin, tolerance) => {
        // Calculate tolerance bounds
        const toleranceLow = 1.0 - tolerance / 100.0;
        const toleranceHigh = 1.0 + tolerance / 100.0;

        // Calculate effective resistance based on the configuration type
        let rEffective;
        switch (configType) {
            case 'parallel':
                rEffective = (r1 * r2) / (r1 + r2);
                break;
            case 'series':
                rEffective = r1 + r2;
                break;
            case 'single':
                rEffective = r1;
                break;
            default:
                throw new Error('Invalid configuration type');
        }

        // Calculate min, nom, and max based on fixed resistor orientation
        let nom, min, max;
        if (fixedResOrientation === 'top') {
            nom = vin * rEffective / (rEffective + fixedRes);
            min = vin * (rEffective * toleranceLow) / (fixedRes * toleranceLow + rEffective * toleranceHigh);
            max = vin * (rEffective * toleranceHigh) / (fixedRes * toleranceHigh + rEffective * toleranceLow);
        } else {
            nom = vin * fixedRes / (rEffective + fixedRes);
            min = vin * (fixedRes * toleranceLow) / (fixedRes * toleranceLow + rEffective * toleranceHigh);
            max = vin * (fixedRes * toleranceHigh) / (fixedRes * toleranceHigh + rEffective * toleranceLow);
        }

        return { min, nom, max };
    };






    const validateInputScientificPrefixes = (rule, value, callback) => {
        const regex = /^-?(?:\d+|\d*\.\d+|\d+\.\d*)(?:[eE][-+]?\d+)?[fFpPnNuUmMkKgGtT]?$/;
        // Regular expression for matching floating-point numbers with scientific notation and metric prefixes

        if (value && !regex.test(value)) {
            callback('Please enter a valid number with an optional metric prefix!');
        } else {
            callback();
        }
    };

    const validateInputScientific = (rule, value, callback) => {
        const regex = /^-?(?:\d+|\d*\.\d+|\d+\.\d*)(?:[eE][-+]?\d+)?$/; // Regular expression for matching floating-point numbers with scientific notation
        if (value && !regex.test(value)) {
            callback('Please enter a valid number!');
        } else {
            callback();
        }
    };

    const extractFloatWithPrefix = (value) => {
        const match = value.match(/^(-?(?:\d+|\d*\.\d+|\d+\.\d*)(?:[eE][-+]?\d+)?)([fFpPnNuUmMkKgGtT]?)$/);
        if (!match) return null;

        let number = parseFloat(match[1]);
        const prefix = match[2];

        switch(prefix.toLowerCase()) {
            case 'f': number *= 1e-15; break;
            case 'p': number *= 1e-12; break;
            case 'n': number *= 1e-9; break;
            case 'u': number *= 1e-6; break;
            case 'm': number *= 1e-3; break;
            case 'k': number *= 1e3; break;
            case 'M': number *= 1e6; break;
            case 'g': number *= 1e9; break;
            case 't': number *= 1e12; break;
            default: break;
        }

        return number;
    };
    const validatePositive = (rule, value, callback) => {
        const numValue = parseFloat(value);
        if (numValue <= 0) {
            callback('The value must be positive.');
        } else {
            callback();
        }
    };

    const validateInputGreaterThanOutput = (rule, value, callback, allValues) => {
        const inputVoltage = parseFloat(allValues.inputvoltage);
        const outputVoltage = parseFloat(value);

        if (inputVoltage <= outputVoltage) {
            callback('Input voltage must be greater than output voltage.');
        } else {
            callback();
        }
    };

    return (
        <div>
            <Form className="form-container" form={form} onFinish={computeSolution}>
                <div className="form-fields">
                <Form.Item
                    name="inputvoltage"
                    label="Input Voltage"
                    rules={[
                        { required: true, message: 'Please enter a value for the Input Voltage!' },
                        { validator: validateInputScientific },
                        { validator: validatePositive },
                    ]}
                >
                    <Input placeholder="Enter Desired Resistance Value" />
                </Form.Item>
                <Form.Item
                    name="outputvoltage"
                    label="Output Voltage"
                    rules={[
                        { required: true, message: 'Please enter a value for the Output Voltage!' },
                        { validator: validateInputScientific },
                        { validator: validatePositive },
                        ({ getFieldValue }) => ({
                            validator: (rule, value, callback) => validateInputGreaterThanOutput(rule, value, callback, { inputvoltage: getFieldValue('inputvoltage') })
                        }),
                    ]}
                >
                    <Input placeholder="Enter Desired Resistance Value" />
                </Form.Item>
                <Form.Item name="fixedresistororientation" label="Fixed Resistor" initialValue="top">
                    <Select>
                        <Select.Option value="top">Top Branch</Select.Option>
                        <Select.Option value="bottom">Bottom Branch</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="fixedresistance"
                    label="Fixed Resistor Value"
                    rules={[
                        { required: true, message: 'Please enter a value for the Fixed Resistor!' },
                        { validator: validateInputScientificPrefixes },
                    ]}
                >
                    <Input placeholder="Enter Desired Resistance Value" />
                </Form.Item>

                <Form.Item name="orientation" label="Calc. Resistor Orientation" initialValue="single">
                    <Select>
                        <Select.Option value="single">Single</Select.Option>
                        <Select.Option value="series">Series</Select.Option>
                        <Select.Option value="parallel">Parallel</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="tolerance" label="Tolerance" initialValue="1.0">

                    <Select>
                        <Select.Option value="0.1">0.1</Select.Option>
                        <Select.Option value="0.25">0.25</Select.Option>
                        <Select.Option value="0.5">0.5</Select.Option>
                        <Select.Option value="1.0">1.0</Select.Option>
                        <Select.Option value="2.0">2.0</Select.Option>
                        <Select.Option value="5.0">5.0</Select.Option>
                        <Select.Option value="10.0">10.0</Select.Option>
                        <Select.Option value="20.0">20.0</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item  name="pref" valuePropName="checked" initialValue={true}>
                    <Checkbox checked={pref} defaultChecked={true} onChange={(e)=>setPref(e.target.checked)}>
                        Use Preferred Values?
                    </Checkbox>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Calculate
                    </Button>
                </Form.Item>
                 </div>
            </Form>

            <div className="table-container">
                {result && (
                    <div>
                        <h2>Calculated Values</h2>
                        {result}
                    </div>
                )
                }
            </div>

        </div>
    );
};

export default ResistorDivider;


