import styled from 'styled-components'

export const LogoStyled = styled.img`

  margin: 10px;
`


export const NavBarStyled = styled.header`

  height: 100px;
  display: flex;
  justify-content: space-between;
  background-color: #004419;



  
   
  .ant-menu-horizontal >.ant-menu-submenu::after,
  .ant-menu-horizontal >.ant-menu-submenu{

    bottom: 10px !important;
  }
  
  .ant-menu-horizontal >.ant-menu-item::after,
  .ant-menu-horizontal >.ant-menu-item{
    bottom: 10px !important;
  }



  `