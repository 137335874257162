import React from 'react';

const AboutPage = (props) => (
    <div className={"PageHome"}>
        <iframe width="840" height="472" src="https://www.youtube.com/embed/NknjE2SBPxw?si=7fPo54RCOglS1lxr"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
            <h2>Hi! I'm Fish, and I hate talking about myself</h2>
    </div>
);

export default AboutPage
