import React, {useState} from 'react';
import './style.css'
import {useEffect} from "react";
import seedrandom from 'seedrandom';

import {QuestionDisplay} from "./QuestionDisplay";

import {Units} from "./units"

const starterQuestions = [


    {
        Format: "What is the cut-off frequency of an RC lowpass filter with capacitance: %sF, and Resistance %sΩ.",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuousLog",
                ULIM: 10e-6, // Upper limit (float)
                LLIM: 1e-12, // Lower limit (float)
                Units: Units.FARADS, // Enum value
            },
            {
                ValueType: "continuousLog",
                ULIM: 100e3, // Upper limit (float)
                LLIM: 1, // Lower limit (float)
                Units: Units.OHMS, // Enum value
            },
        ],
        Formula: function(numbers) {
            return 1/numbers[0]*numbers[1];
        },
        AnswerUnits: Units.HERTZ,
        Error: 20, // Float number indicating a percentage
        ROT: "Cut off frequency: 1/RC"

    },
    {
        Format: "For a stackup with a layer 1 thickness of %smil, and a relative permittivity of %s, calculate the capacitance per square inch",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "discrete",
                possibleVals: [5, 10],
                Units: Units.INCHES, // Enum value
            },
            {
                ValueType: "discrete",
                possibleVals: [3.23, 3.25, 3.65, 4,  9.6],
                Units: Units.UNITLESS, // Enum value
            },
        ],
        Formula: function(numbers) {
            return 0.225*numbers[1]/numbers[0];
        },
        AnswerUnits: "Farads/in",
        Error: 15.0, // Float number indicating a percentage
        ROT: "Capacitance = e0*er*A/h. e0 is 0.225pF/inch. Set A to 1.0, and solve"

    },
    {
        Format: "Convert %sdBm to Watts",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuous",
                ULIM: 40, // Upper limit (float)
                LLIM: 0, // Lower limit (float)
                Units: Units.DECIBELS, // Enum value
            },
        ],
        Formula: function(numbers) {
            return Math.pow(10,numbers[0]/10) / 1000;
        },
        AnswerUnits: Units.WATTS,
        Error: 20, // Float number indicating a percentage
        ROT: "30dBm -> 1W, 10dB => 10x increase/decrease, 3dB => 2x increase decrease in power"

    },
    {
        Format: "%sdB EVM, corresponds to what percent EVM?",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuous",
                ULIM: -20, // Upper limit (float)
                LLIM: -60, // Lower limit (float)
                Units: Units.DECIBELS, // Enum value
            },
        ],
        Formula: function(numbers) {
            return Math.pow(10,numbers[0]/20) * 100;
        },
        AnswerUnits: Units.PERCENTAGE,
        Error: 20, // Float number indicating a percentage
        ROT: "20dB -> 0.1 V/V, -6dB -> 0.5 V/V, -1dB 0.9V/V. Ex: -38dB, is 1%, +2dB. 1.25%"

    },
    {
        Format: "What distance corresponds to a 1/10 wavelength at %sHz, given a relative permittivity of %s?",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuousLog",
                ULIM: 100e9, // Upper limit (float)
                LLIM: 1e9, // Lower limit (float)
                Units: Units.HERTZ, // Enum value
            },
            {
                ValueType: "discrete",
                possibleVals: [3.23, 9.6],
                Units: Units.UNITLESS, // Enum value
            },
        ],
        Formula: function(numbers) {
            const vel = 12.0e9/Math.sqrt(numbers[1]) // Inches per second
            const period = 1/numbers[0]
            return period*vel/10.0;
        },
        AnswerUnits: Units.INCHES,
        Error: 20.0, // Float number indicating a percentage
        ROT: "Velocity of light = 12 inches/ns, divided by sqrt(dk). Find the distance covered in a period and divide by 4"
    },
    {
        Format: "What distance corresponds to a 1/4 wavelength at %sHz, given a relative permittivity of %s?",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuousLog",
                ULIM: 100e9, // Upper limit (float)
                LLIM: 100e6, // Lower limit (float)
                Units: Units.HERTZ, // Enum value
            },
            {
                ValueType: "discrete",
                possibleVals: [1, 3.23, 4, 9.6],
                Units: Units.UNITLESS, // Enum value
            },
        ],
        Formula: function(numbers) {
            const vel = 12.0e9/Math.sqrt(numbers[1]) // Inches per second
            const period = 1/numbers[0]
            return period*vel/4.0;
        },
        AnswerUnits: Units.INCHES,
        Error: 20.0, // Float number indicating a percentage
        ROT: "Velocity of light = 12 inches/ns, divided by sqrt(dk). Find the distance covered in a period and divide by 4"
    },
    {
        Format: "What distance corresponds to a 1/2 wavelength at %sHz, given a relative permittivity of %s?",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuousLog",
                ULIM: 100e6, // Upper limit (float)
                LLIM: 500e3, // Lower limit (float)
                Units: Units.HERTZ, // Enum value
            },
            {
                ValueType: "discrete",
                possibleVals: [1, 2.3, 4],
                Units: Units.UNITLESS, // Enum value
            },
        ],
        Formula: function(numbers) {
            const vel = 299.792e6/Math.sqrt(numbers[1]) // meters per second
            const period = 1/numbers[0]
            return period*vel/2.0;
        },
        AnswerUnits: Units.METERS,
        Error: 20.0, // Float number indicating a percentage
        ROT: "Velocity of light = 300meters/us, divided by sqrt(dk). Find the distance covered in a period and divide by 2"
    },

    {
        Format: "Estimate the bandwidth of a digital signal with rise time %ss",
        ConstantParameters: [
            // Array of objects with UL, LL, and Units

            {
                ValueType: "continuousLog",
                ULIM: 1e-3, // Upper limit (float)
                LLIM: 0.1e-12, // Lower limit (float)
                Units: Units.SECONDS, // Enum value
            },
        ],
        Formula: function(numbers) {
            return 0.35/numbers[0];
        },
        AnswerUnits: Units.HERTZ,
        Error: 15.0, // Float number indicating a percentage
        ROT: "BW = 0.35/Risetime"
    }

]

const ROTQuizPage = () => {
    const shuffledQuestions = shuffleArray(starterQuestions)
    const rng = seedrandom(new Date().getTime().toString());

    const generateRandomNumber = (llim, ulim) =>{
        // const rng = seedrandom(new Date().getTime().toString());
        return rng()*(ulim-llim)+llim;
    }

    const generateRandomNumberLog = (llim, ulim) =>{
        // const rng = seedrandom(new Date().getTime().toString());
        const ll = Math.log10(llim)
        const ul = Math.log10(ulim)
        const lognum = rng()*(ul-ll)+ll

        return Math.pow(10, lognum);
    }



    // useEffect(() => {
    //     setShuffledQuestions(shuffleArray([...shuffledQuestions]));
    // }, []); // Empty dependency array means this effect runs once on mount

    // Fisher-Yates Shuffle
    function shuffleArray(array) {
        const rng = seedrandom(new Date().getTime().toString());
        const arrayCopy = [...array]; // Creating a shallow copy of the array
        for (let i = arrayCopy.length - 1; i > 0; i--) {
            const j = Math.floor(rng() * (i + 1));
            [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
        }
        return arrayCopy;
    }


    const generateConstants = (generatedNumsObj) =>{
        const generatedNumbers = []
        generatedNumsObj.forEach((numObj) =>{
            let randNum= 0.0;
            if (numObj.ValueType==="continuous"){
                randNum =  generateRandomNumber(numObj.LLIM, numObj.ULIM)
            }
            else if(numObj.ValueType==="continuousLog"){
                randNum =  generateRandomNumberLog(numObj.LLIM, numObj.ULIM)
            }
            else{
                const randomIndex = Math.floor(Math.random() * numObj.possibleVals.length);
                randNum =  numObj.possibleVals[randomIndex];
            }
            generatedNumbers.push(randNum)
        })

        return generatedNumbers
    }


    function formatWithMetricPrefix(number) {
        const prefixes = [
            { limit: 1e12, symbol: 'T' },
            { limit: 1e9, symbol: 'G' },
            { limit: 1e6, symbol: 'M' },
            { limit: 1e3, symbol: 'k' },
            { limit: 1, symbol: '' },
            { limit: 1e-3, symbol: 'm' },
            { limit: 1e-6, symbol: 'u' },
            { limit: 1e-9, symbol: 'n' },
            { limit: 1e-12, symbol: 'p' },
            { limit: 1e-15, symbol: 'f' },
        ];

        for (const { limit, symbol } of prefixes) {
            if (Math.abs(number) >= limit) {
                return (number / limit).toPrecision(3) + symbol;
            }
        }

        return number.toPrecision(3);
    }
    function formatArrayWithMetricPrefix(arr) {
        return arr.map(formatWithMetricPrefix);
    }


    return (
        <div className={"ROTQuizPage"}>
            <h1>Rule of Thumb Quiz</h1>
            <h3>No calculators or pencils allowed</h3>

            {
                shuffledQuestions.slice(0,3).map((question,index)=>{
                    const constants = generateConstants(question.ConstantParameters)
                    const answer = question.Formula(constants)
                    const formattedConstants = formatArrayWithMetricPrefix(constants)
                    const ansUnits = question.AnswerUnits
                    return  <QuestionDisplay
                        index={index+1}
                        question={question.Format}
                        constants={formattedConstants}
                        answer={answer}
                        answerUnitsIn ={ansUnits}
                        error={question.Error}
                        rot={question.ROT}
                    />
                })
            }
        </div>
    )

}



export default ROTQuizPage
