
import './App.css';
import CalculatorPage from "./components/CalculatorPage";
import "@aws-amplify/ui-react/styles.css";
import {
    withAuthenticator,
    Button,
} from "@aws-amplify/ui-react";
import { Route, Routes, } from "react-router-dom";
import {  Layout,  theme } from 'antd';
import React from "react";
import ProjectsPage from "./components/ProjectsPage";
import AboutPage from "./components/AboutPage";
import PageHome from "./components/PageHome";
import Nav from "./components/Nav";
import PageContent from "./components/PageContent";
const { Header, Content, Footer } = Layout;


const App = ({ signOut }) => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (

        <Layout>
            <Header  style={{backgroundColor: '#004419',
                height: '100px',
                alignItems:"center"}}>
                <Nav/>
            </Header>
            <Content className="site-layout">
                <PageContent className="page-top"/>
            </Content>

            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#004419',
                    height: '100px',
                    alignItems:"center"
                }}
            >
                <Button onClick={signOut}>Sign Out</Button>
            </Footer>
        </Layout>

    );
};
export default withAuthenticator(App);

