import MarkdownPreview from "@uiw/react-markdown-preview"
import katex from 'katex';
import { getCodeString } from 'rehype-rewrite';
import "./ProjectsPage.css"
export default function MarkdownPreviewBox(props) {
    return (
            <MarkdownPreview
                className="markdown-content"
                source={props.markdown}
                components={{
                    code: ({ children = [], className, ...props }) => {
                        if (typeof children === 'string' && /^\$\$(.*)\$\$/.test(children)) {
                            const html = katex.renderToString(children.replace(/^\$\$(.*)\$\$/, '$1'), {
                                throwOnError: false,
                            });
                            return <code dangerouslySetInnerHTML={{ __html: html }} style={{ background: 'transparent' }} />;
                        }
                        const code = props.node && props.node.children ? getCodeString(props.node.children) : children;
                        if (
                            typeof code === 'string' &&
                            typeof className === 'string' &&
                            /^language-katex/.test(className.toLocaleLowerCase())
                        ) {
                            const html = katex.renderToString(code, {
                                throwOnError: false,
                            });
                            return <code style={{ fontSize: '150%' }} dangerouslySetInnerHTML={{ __html: html }} />;
                        }
                        return <code className={String(className)}>{children}</code>;
                    },
                }}
            />
    )
}
