import React from "react";
import {Route, Routes} from "react-router-dom";
import CalculatorPage from "../CalculatorPage";
import ProjectsPage from "../ProjectsPage";
import AboutPage from "../AboutPage";
import PageHome from "../PageHome";
import {  Layout,  theme } from 'antd';
import bgimage from  './../../images/circuit_board.jpg'
import {PageContentStyled} from "./style";
import ROTQuizPage from "../ROTQuizPage";
const { Header, Content, Footer } = Layout;



const PageContent = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return(
            <PageContentStyled
                style={{
                    padding: 50,
                    minHeight: "100vh",
                    background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${bgimage})`,
                    backgroundAttachment: 'fixed', // Explicitly setting it here
                }}
            >
                <Routes
                    style={{
                        width: '960px'
                    }}
                >
                    <Route path="/calculator" element={<CalculatorPage/>}/>
                    <Route path="/rotquiz" element={<ROTQuizPage/>}/>
                    <Route path="/projects" element={<ProjectsPage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/home" element={<PageHome/>}/>
                    <Route path="/" element={<PageHome/>}/>
                </Routes>
            </PageContentStyled>
    )
}

export default PageContent