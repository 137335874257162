import React from 'react';
import {PageHomeStyled} from "./style";


const PageHome = (props) => (
    <PageHomeStyled className={"PageHome"}>
                <h1>"Engineers like to solve problems. If there are no problems handily available, they will create their own problems"</h1><br/><h2><span>-Some Guy</span></h2>
    </PageHomeStyled>
);

export default PageHome
