import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { createPost } from '../../graphql/mutations';
import { listPosts } from '../../graphql/queries';
import { sha256 } from 'js-sha256';
import { Input, Button, Form, Select, Layout, Card } from 'antd';
import MarkdownPreviewBox from "./MarkdownPreviewBox";
import MarkdownEntryBox from "./MarkdownEntryBox";
import "./ProjectsPage.css"

const exampleMarkdown = `This is to display the 
\`\$\$\c = \\pm\\sqrt{a^2 + b^2}\$\$\`
 in one line

\`\`\`KaTeX
c = \\pm\\sqrt{a^2 + b^2}
\`\`\`
`;


const { TextArea } = Input;
const { Option } = Select;
const { Header, Content } = Layout;
const hashedID = "50100358009181aca24b41b9cbe32e8d53638fb41453ee09f60a4efde3a13aa4";

const ProjectsPage = () => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [userID, setUserID] = useState("");
    const [posts, setPosts] = useState();
    const [entrymode, setEntrymode] = useState(false);
    const [postMarkdown, setPostMarkdown] = useState("")
    const [form] = Form.useForm();

    useEffect(() => {
        let isMounted = true;
        const checkAuthorization = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                const hash = sha256(currentUser.attributes.sub);

                if (isMounted) {
                    setUserID(currentUser.attributes.sub);

                    if (hash === hashedID) {
                        setIsAuthorized(true);
                    }
                }
            } catch (err) {
                console.error('Error checking authorization:', err);
                if (err.data && err.data.errors) {
                    console.error('GraphQL Errors:', err.data.errors);
                }
            }
        };

        checkAuthorization();

        return () => {
            isMounted = false;
        };
    }, []);


    useEffect(() => {
        let isMounted = true;

        async function fetchNotes() {
            const apiData = await API.graphql({ query: listPosts });
            const notesFromAPI = apiData.data.listPosts.items;
            if(notesFromAPI){
                setPosts(notesFromAPI);
            }


        }

        fetchNotes();

        return () => {
            isMounted = false;
        };
    }, []);

    const formatDate = (date) =>{
        var dateobj = new Date(date);
        return dateobj.toLocaleDateString()
    }

    const handleSubmit = async (values) => {
        const newPost = {
            title: values.title,
            content: postMarkdown,
            status: values.status,
            authorId: userID,
        };

        console.log(newPost);

        try {
            await API.graphql(graphqlOperation(createPost, { input: newPost }));
        } catch (err) {
            console.error('Error creating post:', err);
            if (err.data && err.data.errors) {
                console.error('GraphQL Errors:', err.data.errors);
            }
        }
    };


    return (
        <div >


            {posts && posts.map((post, index) => (
                <div className="blog-post" key={index}>
                    <h1 className="blog-title">{post.title}</h1>
                    <p className="created-at">Posted by Fish on {formatDate(post.createdAt)}</p>
                    <MarkdownPreviewBox markdown={post.content}/>
                </div>
                    ))

            }

            <div className="button-holder">
                {isAuthorized && <Button onClick={()=>setEntrymode(!entrymode)}>{entrymode? 'Cancel Entry': "Enter New Post"}</Button>}
            </div>

            <div>
                {entrymode &&

                    <Form
                            className="new-post-form"
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            initialValues={{ status: 'DRAFT' }}
                        >
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input the title!' }]}
                            >
                                <Input placeholder="Title" />
                            </Form.Item>

                            <MarkdownEntryBox
                                setParentMarkdown={setPostMarkdown}
                                defaultValue={exampleMarkdown}
                            />


                            <Form.Item
                                label="Status"
                                name="status"
                            >
                                <Select placeholder="Select a status">
                                    <Option value="DRAFT">Draft</Option>
                                    <Option value="IN_REVIEW">In Review</Option>
                                    <Option value="PUBLISHED">Published</Option>
                                </Select>
                            </Form.Item>


                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Create Post
                                </Button>
                            </Form.Item>
                        </Form>

                }


            </div>

        </div>
    );
};

export default ProjectsPage;
