import {useState} from "react";
import {CalculatorPageStyled} from "./style";

import { Space, Card, Select} from "antd";
import React from "react";
import ResistorCalc from "./resistorCalc";

import LCResonance from "./lcresonance";
import ResistorDivider from "./resistorDivider";


const CalculatorPage = () => {
    const [algo, setAlgo] = useState("resistance-calc")

    const renderAlgoForm = (algoIdentifier) => {
        switch(algoIdentifier){
            case 'resistance-calc':
                return <ResistorCalc/>
            case 'divider':
                return <ResistorDivider/>
            default:
                return <div>Nothing Selected</div>
        }
    }

    return(
        <CalculatorPageStyled>
            <Card  title="DAS CALCULATOR" >

                <h2>Select An Algorithm</h2>
                <div className="form-container">
                    <Space direction="vertical" size={16}>
                        <Select
                            defaultValue="resistance-calc"
                            // style={{width: 800}}
                            onChange={(value)=>setAlgo(value)}
                            options={[
                                {value: 'resistance-calc', label: 'Resistance'},
                                {value: 'divider', label: 'Resistive Divider'},
                            ]}
                        />

                        {renderAlgoForm(algo)}

                    </Space>
                </div>

            </Card>


        </CalculatorPageStyled>
    )
}

export default CalculatorPage



