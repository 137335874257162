function findESeries(tolerance, preferred = true) {
    tolerance = parseFloat(tolerance);
    const base_resistor_values = {
        "E192":[1.0  , 1.01, 1.02, 1.04, 1.05, 1.06, 1.07, 1.09, 1.1 , 1.11, 1.13,
            1.14, 1.15, 1.17, 1.18, 1.2 , 1.21, 1.23, 1.24, 1.26, 1.27, 1.29,
            1.3 , 1.32, 1.33, 1.35, 1.37, 1.38, 1.4 , 1.42, 1.43, 1.45, 1.47,
            1.49, 1.5 , 1.52, 1.54, 1.56, 1.58, 1.6 , 1.62, 1.64, 1.65, 1.67,
            1.69, 1.72, 1.74, 1.76, 1.78, 1.8 , 1.82, 1.84, 1.87, 1.89, 1.91,
            1.93, 1.96, 1.98, 2.  , 2.03, 2.05, 2.08, 2.1 , 2.13, 2.15, 2.18,
            2.21, 2.23, 2.26, 2.29, 2.32, 2.34, 2.37, 2.4 , 2.43, 2.46, 2.49,
            2.52, 2.55, 2.58, 2.61, 2.64, 2.67, 2.71, 2.74, 2.77, 2.8 , 2.84,
            2.87, 2.91, 2.94, 2.98, 3.01, 3.05, 3.09, 3.12, 3.16, 3.2 , 3.24,
            3.28, 3.32, 3.36, 3.4 , 3.44, 3.48, 3.52, 3.57, 3.61, 3.65, 3.7 ,
            3.74, 3.79, 3.83, 3.88, 3.92, 3.97, 4.02, 4.07, 4.12, 4.17, 4.22,
            4.27, 4.32, 4.37, 4.42, 4.48, 4.53, 4.59, 4.64, 4.7 , 4.75, 4.81,
            4.87, 4.93, 4.99, 5.05, 5.11, 5.17, 5.23, 5.3 , 5.36, 5.42, 5.49,
            5.56, 5.62, 5.69, 5.76, 5.83, 5.9 , 5.97, 6.04, 6.12, 6.19, 6.26,
            6.34, 6.42, 6.49, 6.57, 6.65, 6.73, 6.81, 6.9 , 6.98, 7.06, 7.15,
            7.23, 7.32, 7.41, 7.5 , 7.59, 7.68, 7.77, 7.87, 7.96, 8.06, 8.16,
            8.25, 8.35, 8.45, 8.56, 8.66, 8.76, 8.87, 8.98, 9.09, 9.2 , 9.31,
            9.42, 9.53, 9.65, 9.76, 9.88],
        "E96":[1.  , 1.02, 1.05, 1.07, 1.1 , 1.13, 1.15, 1.18, 1.21, 1.24, 1.27,
            1.3 , 1.33, 1.37, 1.4 , 1.43, 1.47, 1.5 , 1.54, 1.58, 1.62, 1.65,
            1.69, 1.74, 1.78, 1.82, 1.87, 1.91, 1.96, 2.  , 2.05, 2.1 , 2.15,
            2.21, 2.26, 2.32, 2.37, 2.43, 2.49, 2.55, 2.61, 2.67, 2.74, 2.8 ,
            2.87, 2.94, 3.01, 3.09, 3.16, 3.24, 3.32, 3.4 , 3.48, 3.57, 3.65,
            3.74, 3.83, 3.92, 4.02, 4.12, 4.22, 4.32, 4.42, 4.53, 4.64, 4.75,
            4.87, 4.99, 5.11, 5.23, 5.36, 5.49, 5.62, 5.76, 5.9 , 6.04, 6.19,
            6.34, 6.49, 6.65, 6.81, 6.98, 7.15, 7.32, 7.5 , 7.68, 7.87, 8.06,
            8.25, 8.45, 8.66, 8.87, 9.09, 9.31, 9.53, 9.76],
        "E96_preferred" : [1.  , 1.1 , 1.21, 1.33, 1.47, 1.62, 1.78, 1.96, 2.15, 2.37, 2.61,
            2.87, 3.16, 3.48, 3.83, 4.22, 4.64, 5.11, 5.62, 6.19, 6.81, 7.5 ,
            8.25, 9.09],
        "E48" : [1.  , 1.05, 1.1 , 1.15, 1.21, 1.27, 1.33, 1.4 , 1.47, 1.54, 1.62,
            1.69, 1.78, 1.87, 1.96, 2.05, 2.15, 2.26, 2.37, 2.49, 2.61, 2.74,
            2.87, 3.01, 3.16, 3.32, 3.48, 3.65, 3.83, 4.02, 4.22, 4.42, 4.64,
            4.87, 5.11, 5.36, 5.62, 5.9 , 6.19, 6.49, 6.81, 7.15, 7.5 , 7.87,
            8.25, 8.66, 9.09, 9.53],
        "E24" :[1.0 , 1.1, 1.2, 1.3, 1.5, 1.6, 1.8, 2.0, 2.2, 2.4, 2.7, 3.0 , 3.3,
            3.6, 3.9, 4.3, 4.7, 5.1, 5.6, 6.2, 6.8, 7.5, 8.2, 9.1],
        "E12" :[1.0,1.2,1.5,1.8,2.2,2.7
            ,3.3,3.9,4.7,5.6,6.8,8.2],
        "E6":[1,1.5,2.2,3.3,4.7,6.8]
    }
    if ([0.5, 0.25, 0.1].includes(tolerance)) {
        return base_resistor_values["E192"];
    } else if (tolerance === 1.0) {
        return preferred ? base_resistor_values["E96_preferred"] : base_resistor_values["E96"];
    } else if (tolerance === 2.0) {
        return base_resistor_values["E48"];
    } else if (tolerance === 5.0) {
        return base_resistor_values["E24"];
    } else if (tolerance === 10.0) {
        return base_resistor_values["E12"];
    } else if (tolerance === 20.0) {
        return base_resistor_values["E6"];
    } else {
        throw new Error("Requested Tolerance Not found! Permissible tolerances: 0.1, 0.25, 0.5, 1, 2, 5, 10, 20");
    }
}

export const parallelR = ({r1, r2}) =>{
    return r1*r2/(r1+r2)
}


export const findOptimalParallelResistance = (targetR, givenR) => {
    if (givenR < targetR) {
        throw new Error("target resistance must be smaller than given resistance");
    }

    if (givenR === targetR) {
        return 100e6;
    } else {
        return (targetR * givenR) / (givenR - targetR);
    }
};

export const findNearestStandardResistors = (target_r, tolerance = 1, pref = true) =>{
    const e_series = findESeries(tolerance, pref); // Assume findESeries function exists
    const decade = Math.floor(Math.log10(target_r));
    const std_vals_from_same_decade = e_series.map(x => x * Math.pow(10, decade));

    const max_from_this_decade = Math.max(...std_vals_from_same_decade);
    const min_from_next_decade = Math.min(...e_series.map(x => x * Math.pow(10, decade + 1)));

    if (target_r > max_from_this_decade) {
        return [max_from_this_decade, min_from_next_decade];
    } else {
        const diffs = std_vals_from_same_decade.map(x => Math.abs(x - target_r));
        const index_1 = diffs.indexOf(Math.min(...diffs));
        const val_1 = std_vals_from_same_decade[index_1];

        const new_array = std_vals_from_same_decade.filter((_, i) => i !== index_1);
        const new_diffs = new_array.map(x => Math.abs(x - target_r));
        const index_2 = new_diffs.indexOf(Math.min(...new_diffs));
        const val_2 = new_array[index_2];

        return [val_1, val_2];
    }
}

export const findOptimalSeriesResistance = (targetR, givenR) => {
    if (givenR > targetR) {
        throw new Error("target resistance must be smaller than given resistance");
    }

    if (givenR === targetR) {
        return 0;
    } else {
        return targetR - givenR;
    }
};

export const findStandardResistorsInRange =(val_low, val_high, tolerance = 1, pref = true) => {
    if (val_low >= val_high) {
        throw new Error("high value lower than low value");
    }

    const lowest_decade = Math.floor(Math.log10(val_low));
    const highest_decade = Math.floor(Math.log10(val_high) + 1.0);

    const e_series = findESeries(tolerance, pref); // Assume findESeries function exists
    const decades = Array.from({length: highest_decade - lowest_decade + 1}, (_, i) => i + lowest_decade);

    const final_values = [];

    for (const decade of decades) {
        // Create a new array consisting of e_series values multiplied by 10 to the power of the integer
        const decade_values = e_series.map(x => x * Math.pow(10, decade));
        for (const value of decade_values) {
            if (value >= val_low && value <= val_high) {
                final_values.push(value);
            }
        }
    }

    return final_values; // Returning a normal array, not a NumPy array
}

export const findSeriesResistorCandidates = (tolerance, targetResistance, pref) => {
    tolerance = parseFloat(tolerance);

    let r1Candidates = findStandardResistorsInRange(targetResistance * 0.4, targetResistance, tolerance, pref);
    let r1R2List = [];

    for (let r1 of r1Candidates) {
        let optimalR2 = findOptimalSeriesResistance(targetResistance, r1);

        let closestR2s = findNearestStandardResistors(optimalR2, tolerance, pref);

        r1R2List.push({r1, r2: closestR2s[0]});
        r1R2List.push({r1, r2: closestR2s[1]});
    }

    return r1R2List.sort((a, b) => Math.abs((a.r1 + a.r2) - targetResistance) - Math.abs((b.r1 + b.r2) - targetResistance));
}

export const findParallelResistorCandidates = (tolerance, targetResistance, pref) => {
    tolerance = parseFloat(tolerance);

    const r1Candidates = findStandardResistorsInRange(targetResistance, targetResistance * 2.1, tolerance, pref);
    const r1R2List = [];

    r1Candidates.forEach((r1) => {
        const optimalR2 = findOptimalParallelResistance(targetResistance, r1);
        const closestR2s = findNearestStandardResistors(optimalR2, tolerance, pref);
        r1R2List.push({ r1, r2: closestR2s[0] });
        r1R2List.push({ r1, r2: closestR2s[1] });
    });

    return r1R2List.sort((a, b) => {
        return Math.abs(parallelR(a) - targetResistance) - Math.abs(parallelR(b) - targetResistance);
    });
}
export const formatRes = (resistance) => {
    if (resistance >= 1e6) {  // Megaohm range
        return `${(resistance / 1e6).toFixed(3)}MΩ`;
    } else if (resistance >= 1e3) {  // Kiloohm range
        return `${(resistance / 1e3).toFixed(3)}kΩ`;
    } else if (resistance >= 1) {  // Ohm range
        return `${resistance.toFixed(3)}Ω`;
    } else if (resistance >= 1e-3) {  // Milliohm range
        return `${(resistance * 1e3).toFixed(3)}mΩ`;
    } else {  // Microohm range
        return `${(resistance * 1e6).toFixed(3)}μΩ`;
    }
};


