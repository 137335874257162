import React, {useEffect, useState} from "react";
import {Button, Card, Form, Input} from "antd";
import {sprintf} from "sprintf-js";
import {Units} from "./units"

export const QuestionDisplay = ({index, question, constants, answer, answerUnitsIn, error, rot}) => {
    const [showROT, setShowROT] = useState(false);
    const [form] = Form.useForm();
    const [message, setMessage] = useState('');
    const [showAnswer, setShowAnswer] = useState(false);
    const [formattedConstants, setFormattedConstants] = useState([]);
    const correctAnswer = answer;
    const displayableAnswer = formatWithMetricPrefix(answer);
    const answerUnits = answerUnitsIn;

    const acceptableError = error;
    const title = sprintf("Question %i", index);

    useEffect(() => {
        // Your code here will run only once after the component mounts.
        console.log(answerUnits)
    }, [answerUnitsIn]); // The empty array means this effect will only run once.


    function formatWithMetricPrefix(number) {
        const prefixes = [
            { limit: 1e12, symbol: 'T' },
            { limit: 1e9, symbol: 'G' },
            { limit: 1e6, symbol: 'M' },
            { limit: 1e3, symbol: 'k' },
            { limit: 1, symbol: '' },
            { limit: 1e-3, symbol: 'm' },
            { limit: 1e-6, symbol: 'u' },
            { limit: 1e-9, symbol: 'n' },
            { limit: 1e-12, symbol: 'p' },
            { limit: 1e-15, symbol: 'f' },
        ];

        for (const { limit, symbol } of prefixes) {
            if (Math.abs(number) >= limit) {
                return (number / limit).toPrecision(3) + symbol;
            }
        }

        return number.toPrecision(3);
    }

    const validateInputScientificPrefixes = (rule, value, callback) => {
        const regex = /^-?(?:\d+|\d*\.\d+|\d+\.\d*)(?:[eE][-+]?\d+)?[fFpPnNuUmMkKgGtT]?$/;
        // Regular expression for matching floating-point numbers with scientific notation and metric prefixes

        if (value && !regex.test(value)) {
            callback('Please enter a valid number with an optional metric prefix!');
        } else {
            callback();
        }
    };

    const extractFloatWithPrefix = (value) => {
        const match = value.match(/^(-?(?:\d+|\d*\.\d+|\d+\.\d*)(?:[eE][-+]?\d+)?)([fFpPnNuUmMkKgGtT]?)$/);
        if (!match) return null;

        let number = parseFloat(match[1]);
        const prefix = match[2];

        switch (prefix) {
            case 'f':
                number *= 1e-15;
                break;
            case 'p':
                number *= 1e-12;
                break;
            case 'n':
                number *= 1e-9;
                break;
            case 'u':
                number *= 1e-6;
                break;
            case 'm':
                number *= 1e-3;
                break;
            case 'k':
                number *= 1e3;
                break;
            case 'M':
                number *= 1e6;
                break;
            case 'G':
                number *= 1e9;
                break;
            case 'T':
                number *= 1e12;
                break;
            default:
                break;
        }

        return number;
    };


    const checkAnswer = (values) => {
        const userAns = extractFloatWithPrefix(values.useranswer);
        console.log(values.useranswer)
        console.log(userAns)
        console.log(Units.AMPS)
        const userError = 100 * (userAns - correctAnswer) / correctAnswer;
        if (Math.abs(userError) <= acceptableError) {
            setMessage("Correct!")
            setShowAnswer(true)
        } else {
            setMessage("Try Again!")
            setShowAnswer(false)
        }
    }

    return (
        <Card key={index} title={title} className="qcard" extra={<Button type="secondary" onClick={() => setShowROT(!showROT)}>
            {showROT ? 'Hide Hint' : 'Show Hint'}
        </Button>} style={{width: 500}}>
            <Form form={form} onFinish={checkAnswer}>
                <p>{sprintf(question, ...constants)}</p>
                <Form.Item
                    name="useranswer"
                    label={sprintf("Answer in %s (within +/-%d%%)",answerUnits, acceptableError)}
                    rules={[
                        {required: true, message: 'Please enter a value!'},
                        {validator: validateInputScientificPrefixes},
                    ]}
                >
                    <Input placeholder="ans"/>
                </Form.Item>
                {message}
                {showAnswer &&
                    <p>Exact Answer: {displayableAnswer}{answerUnits}</p>
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                {showROT &&
                    <div>
                        Rule of Thumb: {rot}
                    </div>
                }
            </Form>
        </Card>
    )

}