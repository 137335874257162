import React, {useRef, useState, useEffect} from "react";
import MDEditor, {commands} from '@uiw/react-md-editor';
import katex from 'katex';
import 'katex/dist/katex.css';
import { getCodeString } from 'rehype-rewrite';
import {Storage} from "aws-amplify";
import {TailSpin} from "react-loader-spinner"
import {v4 as uuidv4} from "uuid";

export default function MarkdownEntryBox(props) {
    const imageInputRef = useRef(null)
    const fileInputRef = useRef(null)
    const apiHandle = useRef(null)

    const [markdownBody, setMarkdownBody] = useState(props.defaultValue)
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        props.setParentMarkdown(markdownBody)
    }, [markdownBody])

    async function selectFileForUpload(api, fileRef) {
        fileRef.current.click()
        apiHandle.current = api
    }

    async function onFileSelectionChange(fileType, fileRef) {
        if (fileRef.current.files[0]) {
            const file = fileRef.current.files[0]
            const fileName = generateUniqueFilename(fileRef.current.files[0].name)

            setIsFetching(true)
            await Storage.configure({level: 'public'});
            let fileUrl = ""
            await Storage.put(fileName, file, {
                ACL: 'public-read'
            }).then(
                fileUrl = "https://calculator1b405b8f06c04ffd81e844709962a95605433-staging.s3.amazonaws.com/public/" + fileName
            )
            // fileUrl = "https://problemdbapp0cbec15c7c5a47e0aee6cc4224e0f5a7233753-staging.s3.amazonaws.com/public/" + fileName

            setIsFetching(false)
            const markdownLink = fileType === "image" ? "<img src=\"" + fileUrl + "\" width='400' height='250'>" : "[" + fileName + "](" + fileUrl + ")"
            apiHandle.current.replaceSelection(markdownLink)
        }
    }

    function generateUniqueFilename(originalFilename) {
        const imageID = uuidv4().slice(-4)
        return  imageID + "-" + originalFilename
    }


    function insertLinkToolbarButton() {
        return {
            name: 'link',
            keyCommand: 'link',
            shortcuts: 'ctrlcmd+k',
            buttonProps: {'aria-label': 'Add a link', title: 'Add a link'},
            icon: (
                <svg width="20" height="20" viewBox="0 0 20 20">
                    <path
                        fill="currentColor"
                        d="M16.469,8.924l-2.414,2.413c-0.156,0.156-0.408,0.156-0.564,0c-0.156-0.155-0.156-0.408,0-0.563l2.414-2.414c1.175-1.175,1.175-3.087,0-4.262c-0.57-0.569-1.326-0.883-2.132-0.883s-1.562,0.313-2.132,0.883L9.227,6.511c-1.175,1.175-1.175,3.087,0,4.263c0.288,0.288,0.624,0.511,0.997,0.662c0.204,0.083,0.303,0.315,0.22,0.52c-0.171,0.422-0.643,0.17-0.52,0.22c-0.473-0.191-0.898-0.474-1.262-0.838c-1.487-1.485-1.487-3.904,0-5.391l2.414-2.413c0.72-0.72,1.678-1.117,2.696-1.117s1.976,0.396,2.696,1.117C17.955,5.02,17.955,7.438,16.469,8.924 M10.076,7.825c-0.205-0.083-0.437,0.016-0.52,0.22c-0.083,0.205,0.016,0.437,0.22,0.52c0.374,0.151,0.709,0.374,0.997,0.662c1.176,1.176,1.176,3.088,0,4.263l-2.414,2.413c-0.569,0.569-1.326,0.883-2.131,0.883s-1.562-0.313-2.132-0.883c-1.175-1.175-1.175-3.087,0-4.262L6.51,9.227c0.156-0.155,0.156-0.408,0-0.564c-0.156-0.156-0.408-0.156-0.564,0l-2.414,2.414c-1.487,1.485-1.487,3.904,0,5.391c0.72,0.72,1.678,1.116,2.696,1.116s1.976-0.396,2.696-1.116l2.414-2.413c1.487-1.486,1.487-3.905,0-5.392C10.974,8.298,10.55,8.017,10.076,7.825">

                    </path>
                </svg>
            ),
            execute: (state, api) => {

                let modifyText = `${state.selectedText}\n`;
                if (!state.selectedText) {
                    modifyText = `desired title`;
                }
                api.replaceSelection('[' + modifyText + '](https://www.google.com)');

            },
        }
    }

    function uploadImageToolbarButton(fileRef) {
        return {
            name: 'ImageUpload',
            keyCommand: 'ImageUpload',
            buttonProps: {'aria-label': 'Insert Image', title: 'Insert Image'},
            icon: (

                <svg width="20" height="20" viewBox="0 0 20 20">
                    <path
                        fill="currentColor"
                        d="M18.555,15.354V4.592c0-0.248-0.202-0.451-0.45-0.451H1.888c-0.248,0-0.451,0.203-0.451,0.451v10.808c0,0.559,0.751,0.451,0.451,0.451h16.217h0.005C18.793,15.851,18.478,14.814,18.555,15.354 M2.8,14.949l4.944-6.464l4.144,5.419c0.003,0.003,0.003,0.003,0.003,0.005l0.797,1.04H2.8z M13.822,14.949l-1.006-1.317l1.689-2.218l2.688,3.535H13.822z M17.654,14.064l-2.791-3.666c-0.181-0.237-0.535-0.237-0.716,0l-1.899,2.493l-4.146-5.42c-0.18-0.237-0.536-0.237-0.716,0l-5.047,6.598V5.042h15.316V14.064z M12.474,6.393c-0.869,0-1.577,0.707-1.577,1.576s0.708,1.576,1.577,1.576s1.577-0.707,1.577-1.576S13.343,6.393,12.474,6.393 M12.474,8.645c-0.371,0-0.676-0.304-0.676-0.676s0.305-0.676,0.676-0.676c0.372,0,0.676,0.304,0.676,0.676S12.846,8.645,12.474,8.645">

                    </path>
                </svg>

            ),

            execute: (state, api) => {
                selectFileForUpload(api, fileRef)
            },
        }
    }

    function uploadFileToolbarButton(fileRef) {
        return {
            name: 'FileUpload',
            keyCommand: 'FileUpload',
            buttonProps: {'aria-label': 'Insert File', title: 'Insert File'},
            icon: (
                <svg width="20" height="20" viewBox="0 0 20 20">
                    <path
                        fill="currentColor"
                        d="M4.317,16.411c-1.423-1.423-1.423-3.737,0-5.16l8.075-7.984c0.994-0.996,2.613-0.996,3.611,0.001C17,4.264,17,5.884,16.004,6.88l-8.075,7.984c-0.568,0.568-1.493,0.569-2.063-0.001c-0.569-0.569-0.569-1.495,0-2.064L9.93,8.828c0.145-0.141,0.376-0.139,0.517,0.005c0.141,0.144,0.139,0.375-0.006,0.516l-4.062,3.968c-0.282,0.282-0.282,0.745,0.003,1.03c0.285,0.284,0.747,0.284,1.032,0l8.074-7.985c0.711-0.71,0.711-1.868-0.002-2.579c-0.711-0.712-1.867-0.712-2.58,0l-8.074,7.984c-1.137,1.137-1.137,2.988,0.001,4.127c1.14,1.14,2.989,1.14,4.129,0l6.989-6.896c0.143-0.142,0.375-0.14,0.516,0.003c0.143,0.143,0.141,0.374-0.002,0.516l-6.988,6.895C8.054,17.836,5.743,17.836,4.317,16.411">

                    </path>
                </svg>
            ),

            execute: (state, api) => {

                selectFileForUpload(api, fileRef)


            },
        }
    }


    return (
        <div data-color-mode="light">
            <input type='file' id='file' ref={fileInputRef} style={{display: 'none'}}
                   onChange={() => onFileSelectionChange("file", fileInputRef)}
            />
            <input type='file' id='image' ref={imageInputRef} style={{display: 'none'}}
                   onChange={() => onFileSelectionChange("image", imageInputRef)}
                   accept="image/*"/>
            {isFetching &&
            <TailSpin
                type="TailSpin"
                color="#000000"
                height={50}
                width={50}
            />}
            <MDEditor
                value={markdownBody}
                onChange={setMarkdownBody}
                commands={[
                    // Custom Toolbars
                    uploadFileToolbarButton(fileInputRef),
                    commands.divider,
                    uploadImageToolbarButton(imageInputRef),
                    commands.divider,
                    insertLinkToolbarButton(),
                    commands.divider,
                    commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
                        name: 'title',
                        groupName: 'title',
                        buttonProps: {'aria-label': 'Set Title', title: 'Set Title'},
                        icon: (
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    fill="currentColor"
                                    d="M10,1.529c-4.679,0-8.471,3.792-8.471,8.471c0,4.68,3.792,8.471,8.471,8.471c4.68,0,8.471-3.791,8.471-8.471C18.471,5.321,14.68,1.529,10,1.529 M10,17.579c-4.18,0-7.579-3.399-7.579-7.579S5.82,2.421,10,2.421S17.579,5.82,17.579,10S14.18,17.579,10,17.579 M14.348,10c0,0.245-0.201,0.446-0.446,0.446h-5c-0.246,0-0.446-0.201-0.446-0.446s0.2-0.446,0.446-0.446h5C14.146,9.554,14.348,9.755,14.348,10 M14.348,12.675c0,0.245-0.201,0.446-0.446,0.446h-5c-0.246,0-0.446-0.201-0.446-0.446s0.2-0.445,0.446-0.445h5C14.146,12.229,14.348,12.43,14.348,12.675 M7.394,10c0,0.245-0.2,0.446-0.446,0.446H6.099c-0.245,0-0.446-0.201-0.446-0.446s0.201-0.446,0.446-0.446h0.849C7.194,9.554,7.394,9.755,7.394,10 M7.394,12.675c0,0.245-0.2,0.446-0.446,0.446H6.099c-0.245,0-0.446-0.201-0.446-0.446s0.201-0.445,0.446-0.445h0.849C7.194,12.229,7.394,12.43,7.394,12.675 M14.348,7.325c0,0.246-0.201,0.446-0.446,0.446h-5c-0.246,0-0.446-0.2-0.446-0.446c0-0.245,0.2-0.446,0.446-0.446h5C14.146,6.879,14.348,7.08,14.348,7.325 M7.394,7.325c0,0.246-0.2,0.446-0.446,0.446H6.099c-0.245,0-0.446-0.2-0.446-0.446c0-0.245,0.201-0.446,0.446-0.446h0.849C7.194,6.879,7.394,7.08,7.394,7.325">

                                </path>
                            </svg>
                        )
                    }),
                    commands.divider
                ]}
                previewOptions={{
                    components: {
                        code: ({ children = [], className, ...props }) => {
                            if (typeof children === 'string' && /^\$\$(.*)\$\$/.test(children)) {
                                const html = katex.renderToString(children.replace(/^\$\$(.*)\$\$/, '$1'), {
                                    throwOnError: false,
                                });
                                return <code dangerouslySetInnerHTML={{ __html: html }} style={{ background: 'transparent' }} />;
                            }
                            const code = props.node && props.node.children ? getCodeString(props.node.children) : children;
                            if (
                                typeof code === 'string' &&
                                typeof className === 'string' &&
                                /^language-katex/.test(className.toLocaleLowerCase())
                            ) {
                                const html = katex.renderToString(code, {
                                    throwOnError: false,
                                });
                                return <code style={{ fontSize: '150%' }} dangerouslySetInnerHTML={{ __html: html }} />;
                            }
                            return <code className={String(className)}>{children}</code>;
                        },
                    },
                }}
                // previewOptions={{
                //     components: {
                //         code: ({inline, children, className, ...props}) => {
                //             const txt = children[0] || '';
                //             if (inline) {
                //                 if (typeof txt === 'string' && /^\$\$(.*)\$\$/.test(txt)) {
                //                     const html = katex.renderToString(txt.replace(/^\$\$(.*)\$\$/, '$1'), {
                //                         throwOnError: false,
                //                     });
                //                     return <code dangerouslySetInnerHTML={{__html: html}}/>;
                //                 }
                //                 return <code>{txt}</code>;
                //             }
                //             if (
                //                 typeof txt === 'string' &&
                //                 typeof className === 'string' &&
                //                 /^language-katex/.test(className.toLocaleLowerCase())
                //             ) {
                //                 const html = katex.renderToString(txt, {
                //                     throwOnError: false,
                //                 });
                //                 return <code dangerouslySetInnerHTML={{__html: html}}/>;
                //             }
                //             return <code className={String(className)}>{txt}</code>;
                //         },
                //     },
                // }}
            />
        </div>
    );
}
