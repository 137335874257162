import React, {useState} from 'react';
import { Form, Input, Button, Select, Checkbox } from 'antd';
import {findParallelResistorCandidates, findSeriesResistorCandidates, parallelR, formatRes} from './Lib/resistorMath'


const ResistorCalc = () => {
    const [form] = Form.useForm();
    const [result, setResult] = useState();
    const [pref, setPref] = useState(true);
    const [orientation, setOrientation] = useState("Series")
    const [plot, setPlot] = useState()

    const computeSolution = (values) => {
        if (values.orientation === "series"){
            setResult (findSeriesResistance(values.resistor, 5, values.tolerance, values.pref))
        } else {
            setResult (findParallelResistance(values.resistor, 5, values.tolerance, values.pref))
        }
    };

    function findSeriesResistance(targetResistance, numResults, tolerance = 1, pref = true) {
        // Convert tolerance to float
        let sortedPairs = findSeriesResistorCandidates(tolerance, targetResistance, pref );
        if (numResults > sortedPairs.length) {
            numResults = sortedPairs.length;
        }

        const numToShow = Math.min(numResults, sortedPairs.length);

        return (
            <div>
                {sortedPairs.slice(0, numToShow).map((pair, index) => {
                    const { r1, r2 } = pair;
                    const sum = r1+r2;
                    const errorOhms = Math.abs(sum - targetResistance);
                    const errorPercent = (100 * errorOhms / targetResistance).toFixed(2);
                    const maxVal = sum *(100.0+tolerance)/100.0;
                    const minVal = sum *(100.0-tolerance)/100.0;
                    return (
                        <div key={index}>
                            <br/>
                            R1: {formatRes(r1)}, R2: {formatRes(r2)}
                            <br/>
                            R1+R2: {formatRes(sum)},
                            <br/>
                            Error(Ω): {formatRes(errorOhms)},
                            <br/>
                            Error(%): {errorPercent}
                            <br/>
                            Range: {formatRes(minVal)} to {formatRes(maxVal)}
                        </div>
                    );
                })}
            </div>
        );

    }



    const findParallelResistance = (targetResistance, numResults, tolerance = 1, pref = true) => {

        const sortedPairs = findParallelResistorCandidates(tolerance,targetResistance,pref)
        const numToShow = Math.min(numResults, sortedPairs.length);

        return (
            <div>
                {sortedPairs.slice(0, numToShow).map((pair, index) => {
                    const { r1, r2 } = pair;
                    const r1ParR2 = parallelR(pair);
                    const max_mult = 1+(tolerance/100.0);
                    const min_mult = 1-(tolerance/100.0);
                    const r1Max = r1 *max_mult;
                    const r1Min = r1 *min_mult;
                    const r2Max = r2 *max_mult;
                    const r2Min = r2 *min_mult;

                    const combinations = [
                        (r1Max * r2Max) / (r1Max + r2Max),
                        (r1Max * r2Min) / (r1Max + r2Min),
                        (r1Min * r2Max) / (r1Min + r2Max),
                        (r1Min * r2Min) / (r1Min + r2Min)
                    ];

                    const maxPar = Math.max(...combinations);
                    const minPar = Math.min(...combinations);


                    const errorOhms = Math.abs(r1ParR2 - targetResistance);
                    const errorPercent = (100 * errorOhms / targetResistance).toFixed(2);
                    return (
                        <div key={index}>
                            <br/>
                            R1: {formatRes(r1)}, R2: {formatRes(r2)}
                            <br/>
                            R1||R2: {formatRes(r1ParR2)},
                            <br/>
                            Error(Ω): {formatRes(errorOhms)},
                            <br/>
                            Error(%): {errorPercent}
                            <br/>
                            Range: {formatRes(minPar)} to {formatRes(maxPar)}
                        </div>
                    );
                })}
            </div>
        );
    }







    const validateInput = (rule, value, callback) => {
        const regex = /^-?(?:\d+|\d*\.\d+|\d+\.\d*)(?:[eE][-+]?\d+)?$/; // Regular expression for matching floating-point numbers with scientific notation
        if (value && !regex.test(value)) {
            callback('Please enter a valid number!');
        } else {
            callback();
        }
    };

    return (
        <div>
            <Form form={form} onFinish={computeSolution}>
                <Form.Item
                    name="resistor"
                    label="Target Resistance"
                    rules={[
                        { required: true, message: 'Please enter a value for Target Resistance!' },
                        { validator: validateInput },
                    ]}
                >
                    <Input placeholder="Enter Desired Resistance Value" />
                </Form.Item>
                <Form.Item name="orientation" label="Orientation" initialValue="series">
                    <Select>
                        <Select.Option value="series">Series</Select.Option>
                        <Select.Option value="parallel">Parallel</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="tolerance" label="Tolerance" initialValue="1.0">

                    <Select>
                        <Select.Option value="0.1">0.1</Select.Option>
                        <Select.Option value="0.25">0.25</Select.Option>
                        <Select.Option value="0.5">0.5</Select.Option>
                        <Select.Option value="1.0">1.0</Select.Option>
                        <Select.Option value="2.0">2.0</Select.Option>
                        <Select.Option value="5.0">5.0</Select.Option>
                        <Select.Option value="10.0">10.0</Select.Option>
                        <Select.Option value="20.0">20.0</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item  name="pref" valuePropName="checked" initialValue={true}>
                    <Checkbox checked={pref} defaultChecked={true} onChange={(e)=>setPref(e.target.checked)}>
                        Use Preferred Values?
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Calculate
                    </Button>
                </Form.Item>
            </Form>
            {result && (
                <div>
                    <h2>Calculated Values</h2>
                    {result}
                </div>

            )

            }
        </div>
    );
};

export default ResistorCalc;


