// import styled from "styled-components";
//
// export const CalculatorPageStyled = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-content: center;
//   text-align: center;
//   font-family: sans-serif;
//
//   .ant-card-head-title {
//     font-size: 30px ;
//     padding: 15px 0px;
//     font-weight: bolder;
//   }
//   .ant-card-head {
//     background-color: #f1c40f;
//     color:white;
//   }
//   h2 {
//     color: #004419;
//   }
//
//
// `
import styled from "styled-components";

export const CalculatorPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Changed from align-content */
  text-align: center;


  .ant-card-head-title {
    font-size: 30px;
    padding: 15px 0px;
    font-weight: bolder;

  }


  .ant-input {
    font-family: "Consolas", monospace;
  }
  .ant-input {
    font-family: "Consolas", monospace;
  }
  .ant-select-selection-item {
    font-family: "Consolas", monospace;
  }
  .ant-table-cell {
    font-family: "Consolas", monospace;
  }
  
  .ant-card-head {
    background-color: #f1c40f;
    color: white;

  }
  h2 {
    color: #004419;
  }

  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;

  }

  .form-fields {
    width: 85%;

  }

  .table-container {
    width: 100%;
    overflow: auto;

  }


  @media (max-width: 768px) {
    .form-fields {
      width: 100%;
    }

    .ant-card-head-title {
      font-size: 24px;
      padding: 10px 0px;
    }

    h2 {
      font-size: 18px;
    }
  }
`;




