import styled from 'styled-components'
import bgimage from  './../../images/circuit_board.jpg'
export const PageContentStyled = styled.div`
  padding-top: 150px;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-attachment: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #004419;
  // Add any additional styling here
`;
